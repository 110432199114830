import React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <section
    style={{
      minHeight: '40vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h3>Pagina nu a fost găsită</h3>
    <Link to="/">Înapoi la pagina principală</Link>
  </section>
);

export default NotFoundPage;
